@import "./theme/animate.min";
@import "./theme/bootstrap";
@import "../fonts/fontawesome";
@import "../fonts/material";
@import "animation";

@import "dropzone/dist/dropzone.css";
@import "dropzone/dist/basic.css";


