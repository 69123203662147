:root {
    --zeever-transition: cubic-bezier(0.165, 0.84, 0.44, 1)
}

body .zeever-animate-init {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) translateX(0) scale(1) rotate(0)
}

.zeever-animate {
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, transform;
}

.zeever-move-up {
    transform: translateY(30px)
}

.zeever-move-down {
    transform: translateY(-30px)
}

.zeever-move-right {
    transform: translateX(-40px)
}

.zeever-move-left {
    transform: translateX(40px)
}

.zeever-flip {
    transform: perspective(500px) translateY(60px) scaleY(.75) rotateX(-40deg)
}

.zeever-scale {
    transform: scale(0.5)
}

.zeever-flip,
.zeever-move-down,
.zeever-move-up,
.zeever-move-right,
.zeever-move-left,
.zeever-scale {
    transition-duration: .7s;
    transition-timing-function: var(--zeever-transition)
}

.zeever-delay-1 {
    transition-delay: .1s
}

.zeever-delay-3 {
    transition-delay: .3s
}

.zeever-delay-5 {
    transition-delay: .5s
}

.zeever-delay-7 {
    transition-delay: .7s
}

.zeever-delay-10 {
    transition-delay: 1s
}

.zeever-delay-20 {
    transition-delay: 2s
}
